<template>
    <div class="grid">
        <div class="col-12 lg:col-12 xl:col-12">
            <div class="card mb-0">
                <label for="id_fornecedor">Filtrar por Fornecedor/Mês/Ano</label><br>
                <Dropdown filter id="id_fornecedor" required="true" v-model="id_fornecedor" :options="fornecedores"
                    optionLabel="label" optionValue="value" placeholder="Selecione um Fornecedor">
                </Dropdown>

                <Dropdown filter id="mes" required="true" v-model="mes" :options="meses" class="ml-3"
                    optionLabel="label" optionValue="value" placeholder="Selecione um Mês">
                </Dropdown>
                <Dropdown filter id="ano" required="true" v-model="ano" :options="anos" class="ml-3" optionLabel="label"
                    optionValue="value" placeholder="Selecione um Ano">
                </Dropdown>
                <Button class="ml-3" :loading="loading" @click="listarPlanilhas()">Filtrar</Button>
            </div>
        </div>
    </div>
    <div class="grid">
        <div class="col-12">
            <div class="col-12 xl:col-12">
                <div class="card">
                    <h5>Planilhas Importadas por Fornecedores</h5>

                    <DataTable v-model:filters="filters" :loading="loading" :value="planilhas" :rows="5"
                        :paginator="true" ref="dt" csvSeparator=";" responsiveLayout="scroll"
                        :globalFilterFields="['razao_social', 'data_upload', 'status', 'mes_referencia']">
                        <template #header>
                            <div class="flex justify-content-end">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search" />
                                    <InputText v-model="filters['global'].value" placeholder="Buscar" />
                                </span>
                                <Button label="Exportar Excel" icon="pi pi-upload" class="p-button-success ml-5"
                                    @click="exportCSV($event)" />
                            </div>
                        </template>
                        <Column v-for="col of cabecalhoPlanilhasImportadas" :field="col.field" :header="col.header"
                            :key="col.field" style="white-space:nowrap">
                            <template #body="{ data }" v-if="col.field === 'dados'">
                                <Button icon="pi pi-search" class="p-button-text" @click="mostrarPlanilha(data)" />
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>

    <Dialog v-model:visible="mostrarPlanilhaModal" :style="{ width: '80%' }" header="Planilha Fornecedor" :modal="true">
        <div>
            <DataTable ref="rf" exportFilename="relatorio[CONSOLIDADO]" csvSeparator=";"
                :value="dadosPlanilhasImportadas" :stickyHeader="true" v-model:selection="selectedProducts" dataKey="id"
                :paginator="true" :rows="10" :filters="filters"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                :rowsPerPageOptions="[5, 10, 25]"
                currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
                responsiveLayout="scroll">
                <template #header>
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <h5 class="m-0">Dados Consolidados - Workflow Compartilhamento</h5>
                        <span class="block mt-2 md:mt-0 p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filters['global'].value" placeholder="Buscar" />
                        </span>
                        <Button label="Exportar Excel" icon="pi pi-upload" class="p-button-success ml-5"
                            @click="exportCSV2($event)" />
                    </div>
                </template>

                <Column v-for="col in cabecalhoModal" :field="col.field" :header="col.header" :key="col.field"
                    style="white-space:nowrap">
                </Column>
            </DataTable>
        </div>
    </Dialog>
</template>

<script>
import { FilterMatchMode } from "primevue/api";

export default {
    data() {
        return {
            id_fornecedor: '',
            mes: '',
            ano: '',
            mostrarPlanilhaModal: false,
            planilhas: [],
            anos: [
                { label: "", value: "" },
                { label: "2021", value: "2021" },
                { label: "2022", value: "2022" },
                { label: "2023", value: "2023" },
                { label: "2024", value: "2024" },
                { label: "2025", value: "2025" }
            ],
            meses: [
                { label: "", value: "" },
                { label: "Janeiro", value: "01" },
                { label: "Fevereiro", value: "02" },
                { label: "Março", value: "03" },
                { label: "Abril", value: "04" },
                { label: "Maio", value: "05" },
                { label: "Junho", value: "06" },
                { label: "Julho", value: "07" },
                { label: "Agosto", value: "08" },
                { label: "Setembro", value: "09" },
                { label: "Outubro", value: "10" },
                { label: "Novembro", value: "11" },
                { label: "Dezembro", value: "12" },
            ],
            cabecalhoOperadora: [
                { header: "Site ID TIM", field: 'site_id_tim' },
                { header: "END ID TIM", field: 'end_id_tim' },
                { header: "Regional TIM", field: 'regional_tim' },
                { header: "UF", field: 'uf' },
                { header: "Detentora", field: 'detentora' },
                { header: "Site ID Detentora", field: 'site_id_detentora' },
                { header: "Nº SCI Inicial", field: 'n_sci_inicial' },
                { header: "CC (kWh)", field: 'cc_kwh' },
                { header: "CA (kWh)", field: 'ca_kwh' },
                { header: "Climatização (kWh)", field: 'climatizacao_kwh' },
                { header: "Energia Essencial (kWh)", field: 'energia_essencial_kwh' },
                { header: "Total de Consumo (kWh)", field: 'total_de_consumo_kwh' },
                { header: "Tarifa Utilizada Operadora", field: 'tarifa_utilizada_operadora' },
                { header: "Valor Total", field: 'valor_total' },
                { header: "Tipo Energia", field: 'tipo_de_energia' },
                { header: "É viável instalação Medidor TIM", field: 'viabilidade_instalacao_medidor_tim' },
                { header: "Justificativa", field: 'justificativa' },
                { header: "Observação Detentora", field: 'observacao_detentora' },
                { header: "Mês de Referência do Formulário", field: 'mes_ref_formulario' },
                { header: "Data de Envio do Formulário", field: 'data_envio_formulario' },
                { header: "Mês de Referência da Fatura", field: 'mes_ref_fatura' },
                { header: "Valor total da Fatura", field: 'valor_total_fatura' },
                { header: "Motivo Contestação", field: 'dados_contestacao_unificados' },
            ],
            cabecalhoTower: [
                { header: "Site ID TIM", field: 'site_id_tim' },
                { header: "END ID TIM", field: 'end_id_tim' },
                { header: "Regional TIM", field: 'regional_tim' },
                { header: "UF", field: 'uf' },
                { header: "Detentora", field: 'detentora' },
                { header: "Site ID Detentora", field: 'site_id_detentora' },
                { header: "Data RFI", field: 'data_rfi' },
                { header: "Tipo Energia", field: 'tipo_de_energia' },
                { header: "Consumo (kWh)", field: 'consumo_kwh' },
                { header: "É viável instalação Medidor TIM", field: 'viabilidade_instalacao_medidor_tim' },
                { header: "Justificativa", field: 'justificativa' },
                { header: "Observação Detentora", field: 'observacao_detentora' },
                { header: "Mês de Referência do Formulário", field: 'mes_ref_formulario' },
                { header: "Data de Envio do Formulário", field: 'data_envio_formulario' },
                { header: "Mês de Referência da Fatura", field: 'mes_ref_fatura' },
                { header: "Valor total da Fatura", field: 'valor_total_fatura' },
                { header: "Motivo Contestação", field: 'dados_contestacao_unificados' },
            ],
            cabecalhoPlanilhasImportadas: [
                { header: "Data Upload", field: 'created_at' },
                { header: "Fornecedor", field: 'razao_social' },
                { header: "Número do Lote", field: 'planilha_id' },
                { header: "Responsável", field: 'usuario_responsavel' },
                { header: "End ID Enviados", field: 'total_dados' },
                { header: "Dados", field: 'dados' },
            ],
            empresas: [],
            productDialog: false,
            deleteProductDialog: false,
            deleteProductsDialog: false,
            empresa: {},
            selectedProducts: null,
            filters: {},
            submitted: false,
            status: 0,
            fornecedores: [],
            statuses: [
                { label: "Inativo", value: "0" },
                { label: "Ativo", value: "1" },
            ],
            dadosPlanilhasImportadas: [],
            tipoPlanilha: ""
        };
    },
    created() {
        this.initFilters();
    },
    mounted() {
        this.listarPlanilhas();
        this.listarFornecedor();
    },
    computed: {
        cabecalhoModal() {
            if (this.tipoPlanilha === 'OPERADORA') {
                return this.cabecalhoOperadora;
            } else if (this.tipoPlanilha === 'TOWER') {
                return this.cabecalhoTower;
            }
            return [];
        }
    },
    methods: {
        mostrarPlanilha(rowData) {
            this.tipoPlanilha = rowData.tipo;
            this.listarDadosPlanilhasImportadas(rowData.planilha_id, rowData.tipo);
            this.mostrarPlanilhaModal = true;
        },
        listarRelatorio(id_planilha) {
            let dados = { id_planilha };
            this.axios
                .post("/relatorios/consolidado", dados)
                .then((response) => {
                    console.log(response);
                    this.empresas = response.data.dados;
                })
                .catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: "Chamar o T.I.",
                        life: 3000,
                    });
                });
        },
        listarPlanilhas() {
            const dados = {
                id_fornecedor: this.id_fornecedor,
                mes: this.mes,
                ano: this.ano,
            };

            this.axios
                .post("/relatorios/planilhas-importadas", dados)
                .then((response) => {
                    console.log(response);
                    this.planilhas = response.data.dados;
                })
                .catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: "Chamar o T.I.",
                        life: 3000,
                    });
                });
        },
        listarDadosPlanilhasImportadas(planilha_id, tipo) {
            let dados = {
                id_importacao: planilha_id,
                tipo_upload: tipo
            };
            this.axios
                .post("/relatorios/dados-planilhas-importadas", dados)
                .then((response) => {
                    console.log(response);
                    this.dadosPlanilhasImportadas = response.data.dados;
                })
                .catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: "Chamar o T.I.",
                        life: 3000,
                    });
                });
        },
        listarFornecedor() {
            this.axios
                .post("/fornecedores/listar")
                .then((response) => {
                    let options = [];
                    let desc_fornecedores = {};
                    response.data.dados.map((el) => {
                        let option = { value: el["id"], label: el["razao_social"] };
                        desc_fornecedores[el["id"]] = el["razao_social"];
                        options.push(option);
                    });
                    this.fornecedores = options;
                    this.desc_fornecedores = desc_fornecedores;
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        exportCSV() {
            this.$refs.dt.exportCSV();
        },
        exportCSV2() {
            this.$refs.rf.exportCSV();
        },
        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
    },
};
</script>

<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
</style>
